import React, { Component } from 'react';
import bcss from '@blueprintjs/core/lib/css/blueprint.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import calcss from 'react-big-calendar/lib/css/react-big-calendar.css';
import ICAL from 'ical.js';
import moment from 'moment-timezone';
//import firebase from 'firebase/app';
import FileReaderInput from 'react-file-reader-input';
import './App.css';
import {
    AnchorButton,
    Label
} from "@blueprintjs/core";

const localizer = momentLocalizer(moment);

/* Initialize Firebase
var config = {
  apiKey: "AIzaSyC3GyEW0xkFTdhBi2i2VcU_19k52EEhDuU",
  authDomain: "ou-dashboard.firebaseapp.com",
  databaseURL: "https://ou-dashboard.firebaseio.com",
  storageBucket: "ou-dashboard.appspot.com",
  messagingSenderId: "730343050198"
};
firebase.initializeApp(config);

const db = firebase.database();

// FirebaseUI config.
var uiConfig = {
  'signInOptions': [
    // Leave the lines as is for the providers you want to offer your users.
    {provider: firebase.auth.EmailAuthProvider.PROVIDER_ID, requireDisplayName: false},
    firebase.auth.GoogleAuthProvider.PROVIDER_ID
  ],
  'callbacks': {
    'signInSuccess': function(currentUser, credential, redirectUrl) {
      return false;
    }
  }
}; */

// Initialize the FirebaseUI Widget using Firebase.
// window.firebase = firebase;
//var auth = firebase.auth();
//var ui = new window.firebaseui.auth.AuthUI(auth);

function Event({ event }) {
  return (
    <span>
      <strong>
      {event.title}
      </strong><br />
      { event.desc }
    </span>
  )
}


class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: null,
      hasCalendar: false,
      checkboxes: [],
      myEventsList: []
    };
  }

  componentDidMount() {
    var _this = this;
/*

    firebase.auth().onAuthStateChanged(function(user) {
      _this.setState({loggedIn: !!user});
      if (!user) {
        ui.start('#firebaseui-auth-container', uiConfig);
        return;
      }
      if (_this.state.hasCalendar) {
        var key = '/calendars/' + user.uid;
        var updates = {};
        updates[key] = {
          myEventsList: _this.state.myEventsList,
          checkboxes: []
        };
        //db.ref().update(updates);
      }
      var calRef = firebase.database().ref('calendars/' + user.uid);
      calRef.on('value', function(snapshot) {
        var val = snapshot.val() || {};
        window.localStorage.myEventsList = JSON.stringify(val.myEventsList);
        val.hasCalendar = !!val.myEventsList;
        val.loggedIn = true;
        _this.setState(val)
      });
    });

    */
    if (window.localStorage.myEventsList) {
      _this.setState({hasCalendar: true, myEventsList: JSON.parse(window.localStorage.myEventsList)});
    }
  }

  processICAL(events) {
    var myEventsList = [];
    events.forEach(function (event) {
      var parsed = {};
      event[1].forEach(function (item) {
        parsed[item[0]] = item[3];
      })
      var title = parsed.summary;
      title = title.replace(' (Closes)', '')
      title = title.replace(' closes', '')
      
      if (title.indexOf('Opens') === -1 &&
	  title.indexOf('Practice quiz') === -1 &&
          title.indexOf(' opens') === -1) {
        myEventsList.push({
          startDate: moment(parsed.dtstart).tz('Europe/London'),
          startFormatted: moment(parsed.dtstart).tz('Europe/London').format('YYYY-MM-DD'),
          endDate: new Date(parsed.dtstart),
          allDay: true,
          title: title,
          desc: parsed.categories.replace('-23J', '')
        });
      }
    });
    /*if (auth.currentUser) {
      var key = '/calendars/' + auth.currentUser.uid;
      var updates = {};
      updates[key] = {
        myEventsList: myEventsList,
        checkboxes: []
      };
      db.ref().update(updates);
    }*/
    window.localStorage.myEventsList = JSON.stringify(myEventsList);
    this.setState({myEventsList: myEventsList, hasCalendar: true});
  }

  handleFileChange(e, res) {
    var reader = new FileReader();
    var _this = this;
    reader.onload = function(event){
      _this.processICAL(ICAL.parse(event.target.result)[2]);
    };
    reader.readAsBinaryString(res[0][1]);
  }

  render() {
    var _this = this;
    var style = {height: '600px', width: '650px', float: 'left', 'margin': '30px'};
    var ulstyle = {'textAlign': 'left', 'listStyle': 'none', 'margin': '30px', 'float': 'left'}
    var timeSlots = 4;
    var checkboxes = [];
    if (this.state.hasCalendar) {
      this.state.myEventsList.forEach(function (ev) {
        checkboxes.push(
          <Label>
            {ev.desc}: <strong>{ev.title}</strong> ({ev.startFormatted})
          </Label>
        )
      });
    }

    var ss1 = process.env.PUBLIC_URL + "/screenshot1.png";
    var ss2 = process.env.PUBLIC_URL + "/screenshot2.png";
    var ss3 = process.env.PUBLIC_URL + "/screenshot3.png";

    return (
      <div className="App">
        <link rel="stylesheet" src={calcss} />
        <link rel="stylesheet" src={bcss} />
        {!this.state.hasCalendar && <div>
          <h1>Instructions</h1>

<ol>
<li>Go to <a href="https://msds.open.ac.uk/students/">your StudentHome</a>.</li>
<li><p>Click on 'Accesss to your personal calendar':</p>

<p><img src={ss1} alt="Screenshot" /></p></li>

<li><p>Click on 'Export calendar':</p>

<p><img src={ss2} alt="Screenshot"  /></p></li>

<li><p>Check the 'All events' and 'Custom range' boxes:</p>

<p><img src={ss3} alt="Screenshot"  /></p></li>

<li><p>Click the 'Export' button</p></li>

<li>Upload the file here: <FileReaderInput
                as="text"
                className="pt-file-upload-input"
                onChange={this.handleFileChange.bind(this)}>
            <AnchorButton text="Choose file" />
          </FileReaderInput></li>
</ol>
        </div>}
        {this.state.hasCalendar && <div>
          <Calendar
            style={style}
            events={this.state.myEventsList}
            localizer={localizer}
            startAccessor='startDate'
            endAccessor='endDate'
            timeslots={timeSlots}
            components={{
              event: Event
            }}
            views={['month']}
            showAllEvents
          />
          <ul style={ulstyle}>
            {checkboxes}
          </ul>
        </div>}
        {this.state.hasCalendar && <AnchorButton text="Delete calendar" className="pt-intent-warning" onClick={deleteCalendar} />}
        {this.state.hasCalendar && !this.state.zapierPosted && !this.state.zapierError && <div className="zapier">
          <a href="https://zapier.com/">Zapier</a> webook URL: <input className="pt-input" type="text" placeholder="https://hooks.zapier.com/hooks/catch/..." dir="auto" value={this.state.zapierUrl} onChange={saveZapierUrl} />
          <AnchorButton text="Export" onClick={exportZapier} />
        </div>}
        {this.state.zapierPosted && <div className="zapier">Sent to Zapier!</div>}
        {this.state.zapierError && <div className="zapier">Error sending to Zapier</div>}
      </div>
    );
	  /*
        {this.state.loggedIn
          ? (<div className="logoutBox"><AnchorButton text="Log out" onClick={signOut} /><AnchorButton className="pt-intent-danger" text="Remove user" onClick={removeUser} /></div>)
          : (<h2>Optionally log in to store your calendar on the server:</h2>)}
*/
    function saveZapierUrl (event) {
      if (event && event.target) {
        _this.setState({zapierUrl: event.target.value})
      }
    }

    function exportZapier () {
      fetch(_this.state.zapierUrl, {
        method: "POST",
        body: JSON.stringify(_this.state.myEventsList.map(function (ev) {
          return {
            title: ev.desc + ': ' + ev.title,
            dueDate: ev.startDate
          }
        }))
      }).then(function (response) {
        if (response.ok) {
          _this.setState({zapierPosted: true, zapierError: false});
        } else {
          _this.setState({zapierPosted: false, zapierError: true});
        }
      })
    }

    function deleteCalendar () {
      _this.setState({hasCalendar: false});
      /*if (auth.currentUser) {
        firebase.database().ref('calendars/' + auth.currentUser.uid).remove();
      }*/
      delete localStorage.myEventsList;
    }
  }
}

/*
function signOut () {
  auth.signOut();
}

function removeUser() {
  firebase.database().ref('calendars/' + auth.currentUser.uid).remove(function () {
    auth.currentUser.delete().then(signOut).catch(function() {
      alert('Removing failed. Please try logging out, logging in again, and then removing again.');
    });
  });
}
*/

export default App;
